/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.custom-text {
  @apply text-lg text-slate-950;
}

.form-info {
  @apply border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.form-error {
  @apply border border-red-500   text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500;
}

.content {
  @apply relative  pt-[56px] h-screen;
}

.side-bar-menu {
  .active {
    background-color: #656565;

    .menu-icon {
      color: #c8001d !important;
    }
  }

  .menu-item {
    &:hover {
      .menu-icon {
        color: #c8001d;
      }
    }

    .menu-icon {
      color: #656565;
      height: 1.25rem !important;
      width: 1.25rem !important;
    }
  }
}

.menu-list {
  transition: all 0.4s ease;
}

.tabs-active {
  @apply bg-gray-200 text-[#c8001d] hover:text-[#c8001d] dark:text-[#c8001d] dark:hover:text-blue-400 border-gray-200 hover:bg-gray-200   dark:border-blue-500 #{!important};
}

.tabs-active-white {
  @apply bg-white text-[#c8001d] hover:text-[#c8001d] dark:text-[#c8001d] dark:hover:text-blue-400 border-white hover:bg-white   dark:border-blue-500 #{!important};
}

.btn {
  @apply text-sm px-3 py-2 font-medium rounded-lg focus:outline-none focus:ring-4;

  &.btn-danger {
    @apply text-white bg-red-700 hover:bg-red-800  focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900;
  }

  &.btn-warning {
    @apply text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 dark:focus:ring-yellow-900;
  }

  &.btn-secondary {
    @apply text-gray-900 bg-white border border-gray-300  hover:bg-gray-100  focus:ring-gray-100  dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700;
  }

  &.btn-gray {
    @apply text-gray-900 bg-gray-200 border border-gray-300  hover:bg-gray-100  focus:ring-gray-100  dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700;
  }

  &.btn-success {
    @apply text-white bg-green-700 hover:bg-green-800  focus:ring-green-300  dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800;
  }

  &.btn-info {
    @apply text-white bg-blue-700 hover:bg-blue-800  focus:ring-blue-300  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800;
  }
}

.datatable {
  @apply relative overflow-auto;

  &.custom-scroll-bar {
    @apply [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500;
  }

  .freeze {
    position: sticky;
    right: -1px;
    z-index: 5;
    @apply bg-white;
    /* @apply odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800; */

    &::before {
      content: "";
      position: absolute;
      left: -6px;
      top: 0;
      bottom: -1px;
      width: 5px;
      border-right: 1px solid var(--borderColor);
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.08) 100%);
    }
  }
}

.disabled {
  @apply cursor-not-allowed disabled:bg-gray-50  #{!important};
}

.card {
  @apply bg-white p-4 rounded-lg;
}

.alert-backdrop {
  background-color: rgba(0, 0, 0, 0.1);
}
